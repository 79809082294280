import React, { Component } from "react";
import Header from "../components/Header";
import burger from "../assets/burger.jpg"
import vector from "../assets/Vector.png"
import appstore from "../assets/appstore.jpg"
import googleplaystore from "../assets/googleplaystore.png"
import karachi from "../assets/karachi.png"
import lahore from "../assets/lahore.png"
import islamabad from "../assets/islamabad.png"
import rawalpindi from "../assets/rawalpindi.png"
import faisalabad from "../assets/faisalabad.png"
import multan from "../assets/multan.png"
import { withRouter } from "../components/withRouter";
import { Path } from "../Path/Path";
import { Icon } from '@iconify/react';
// import emailIcon from '@iconify-icons/mdi/email';
// import phoneIcon from '@iconify-icons/mdi/phone';
// import facebookIcon from '@iconify-icons/mdi/facebook';
// import twitterIcon from '@iconify-icons/mdi/twitter';
// import instagramIcon from '@iconify-icons/mdi/instagram';
class HomePage extends Component {
    state = {
        stores: [],
        iconPressed: false,
        iconReleased: false,
        iconPressed2: false,
        iconReleased2: false,
    };
    OnMouseIn = () => {
        this.setState({ iconPressed: true });
        this.setState({ iconReleased: false });
    };

    OnMouseOut = () => {
        this.setState({ iconReleased: true });
        this.setState({ iconPressed: false });
    };

    OnMouseIn2 = () => {
        this.setState({ iconPressed2: true });
        this.setState({ iconReleased2: false });
    };

    OnMouseOut2 = () => {
        this.setState({ iconReleased2: true });
        this.setState({ iconPressed2: false });
    };

    componentDidMount() {
        fetch(`${Path}store/get/all`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ stores: data });
            })
            .catch((error) => console.error('Error fetching data:', error));
    }

    openInGooglePlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.hermosoapp', '_blank');
    };

    openInAppStore = () => {
        window.open('https://apps.apple.com/pk/app/hermosoapp/id6444050913', '_blank');
    };
    render() {
        const { iconPressed, iconReleased, iconPressed2, iconReleased2 } = this.state;
        return (
            <div>
                <Header />
                <div className="hero-section">
                    <div className="container ">
                        <div className="row m-0 p-0">
                            <div className="col-md-6 main2">

                                <div>  <h1 className="hero-section-heading">Discover Most Affordable Salons, Spa, Aesthetic Clinics and Dental Clinic on Hermoso App</h1></div>

                                <div> <p className="hero-section-txt">Download Our App Now!</p></div>


                                <div className="appButtons">
                                    <img
                                        src={googleplaystore}
                                        height="90"
                                        width="200"
                                        onMouseEnter={this.OnMouseIn}
                                        onMouseLeave={this.OnMouseOut}
                                        onClick={this.openInGooglePlayStore}
                                        style={{
                                            marginRight: 20,
                                            marginTop: 7,
                                            cursor: 'pointer',
                                            transform: iconPressed ? 'scale(0.95)' : 'scale(1)',
                                            transition: 'transform 200ms ease'
                                        }}
                                    />

                                    <img
                                        src={appstore}
                                        height="77"
                                        width="200"
                                        onMouseEnter={this.OnMouseIn2}
                                        onMouseLeave={this.OnMouseOut2}
                                        onClick={this.openInAppStore}
                                        style={{
                                            cursor: 'pointer',
                                            transform: iconPressed2 ? 'scale(0.95)' : 'scale(1)',
                                            transition: 'transform 200ms ease'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container-fluid gallery">
                    <div style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <div><p className="menu">Registered Service Providers</p></div>
                    </div>

                </div>
                {/* <div className="container-fluid m-0 p-0 burger-div">
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        <div className="burger">
                            <img width="100%" src={karachi}></img>
                            <div>
                                <p style={{ minWidth: "320px", maxWidth: "396px", width: "100%", fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px" }}>Karachi</p>
                            </div>
                        </div>
                        <div className="burger">
                            <img width="100%" src={lahore}></img>
                            <p style={{ minWidth: "320px", maxWidth: "396px", width: "100%", fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px" }}>Lahore</p>
                        </div>
                        <div className="burger">
                            <img width="100%" src={islamabad}></img>
                            <p style={{ minWidth: "320px", maxWidth: "396px", width: "100%", fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px" }}>Islamabad</p>
                        </div>

                    </div>
                </div> */}
                <div className="container">
                    <div className="row">
                        {this.state.stores.map((store, index) => (
                            <div key={store._id} className="col-md-4 mb-4">
                                <div className="store-card">
                                    <h3>{store.name}</h3>
                                    <img
                                        src={store.logo}
                                        alt={store.name}
                                        className="store-image"
                                        style={{ width: "100%", height: "280px", objectFit: "cover" }}
                                    />
                                    <p>{store.address}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Elegant and Responsive Footer */}
                <div style={{ backgroundColor: "#333", color: "#fff", padding: "20px 0", textAlign: "center" }}>
                    <div style={{ maxWidth: "1200px", margin: "0 auto", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>

                        {/* Left Div - Social Media Links */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <a href="https://www.facebook.com/share/Jw34qj29KfYUQLJG/?mibextid=WC7FNe" target="_blank" rel="noopener noreferrer">
                                <Icon icon="devicon:facebook" style={{ fontSize: "24px", margin: "0 10px", color: "#fff" }} />
                            </a>
                            <a href="https://x.com/hermosoapp?s=21&t=-rW2ocyx01Ro1DlRDPKBiA" target="_blank" rel="noopener noreferrer">
                                <Icon icon="skill-icons:twitter" style={{ fontSize: "24px", margin: "0 10px", color: "#fff" }} />
                            </a>
                            <a href="https://www.instagram.com/hermosoapp?igsh=MXVyNnRvN202eXFocQ==" target="_blank" rel="noopener noreferrer">
                                <Icon icon="skill-icons:instagram" style={{ fontSize: "24px", margin: "0 10px", color: "#fff" }} />

                            </a>
                            <a href="https://www.tiktok.com/@hermosoapp?_t=8jRkI0BDPj0&_r=1" target="_blank" rel="noopener noreferrer">
                                <Icon icon="logos:tiktok-icon" style={{ fontSize: "24px", margin: "0 10px", color: "#fff" }} />

                            </a>
                            <a href="https://youtube.com/@HermosoApp?si=jvzjoTVFlSN9Ao1s" target="_blank" rel="noopener noreferrer">
                                <Icon icon="logos:youtube-icon" style={{ fontSize: "24px", margin: "0 10px", color: "#fff" }} />

                            </a>
                        </div>

                        {/* Right Div - Contact Information */}
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                            <div style={{ marginBottom: "10px" }}>
                                {/* <Icon icon={emailIcon} style={{ fontSize: "18px", marginRight: "10px", color: "#fff" }} /> */}
                                <span>Email: support@hermoso.app</span>
                            </div>
                            <div>
                                {/* <Icon icon={phoneIcon} style={{ fontSize: "18px", marginRight: "10px", color: "#fff" }} /> */}
                                <span>Phone: (051)-8449236</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(HomePage);