import logo from './logo.svg';
import "./css/Main.css"
import './App.css';
import Header from './components/Header';
import Register from './screens/Register';
import HomePage from './screens/HomePage';
import { withRouter } from './components/withRouter';
import { Routes, Route } from "react-router-dom"
import React from 'react';
import AppLink from './screens/AppLink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Formhermoso from './screens/formhermoso';
import PrivacyPolicy from './screens/Privacy&Policy';
function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/register" element={<Register />} />
        <Route path="/applink" element={<HomePage />} />
        <Route path="/form" element={<Formhermoso />} />
      </Routes>
    </React.Fragment>
  );
}

export default withRouter(App);
