import React, { Component } from "react";
import { withRouter } from "../components/withRouter";
class AppLink extends Component {
    render() {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>

                <a href="https://play.google.com/store/apps/?hl=en&gl=US" target="_blank">playstore.com</a>
            </div>
        )
    }
}
export default withRouter(AppLink);