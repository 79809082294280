import React, { Component } from "react";
import Header from "../components/Header";
import back from "../assets/back.png"
import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import DropDown from "../components/DropDown";

import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import AlertModal from "../components/AlertModal";

import ServiceAddModel from "../components/LogoServiceAddModal";
import GalleryServiceAddModal from "../components/GalleryServiceAddModal";
import NewImagePicker from "../components/NewImagePicker";
import CoverServiceAddModal from "../components/CoverServiceAddModal";
import LogoServiceAddModal from "../components/LogoServiceAddModal";
import OfferServiceAddModal from "../components/OffersServiceAddModal";
import OffersServiceAddModal from "../components/OffersServiceAddModal";
import getImageURL from "../Config/UploadImageFunction";
import NewPickerComp from "../components/NewPickerComp";
import { signUp, storeAdd } from "../Path/Path";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
class formhermoso extends Component {
    state = {
        email: "",
        offerImage: [],
        galleryImage: [],
        logoImage: [],
        coverImage: [],
        password: "",
        type: "",
        name: "",
        about: "",
        phoneNumber: "",
        website: "",
        offers: [],
        ntn: "",
        zipCode: "",
        country: "",
        statehermoso: "",
        city: "",
        businessAddress: "",
        incorporationLetter: "",
        modalopen: false,
        loading: false,
        isOpen: false,
        mondayo: "",
        mondayc: "",
        tuesdayo: "",
        tuesdayc: "",
        wednesdayo: "",
        wednesdayc: "",
        thursdayo: "",
        thursdayc: "",
        fridayo: "",
        fridayc: "",
        saturdayo: "",
        saturdayc: "",
        sundayo: "",
        sundayc: "",
        gallery: [],
        logo: '',
        cover: '',
        incorporation: "",
        offersUploadFiles: [],
        galleryUploadFiles: [],
        logoUploadFiles: [],
        coverUploadFiles: [],
        incorporationUploadFiles: [],
        videoLink: "",
        allTimings: [],
        locationnew: "",
        location: {
            latitude: 0,
            longitude: 0
        },
    }

    // handler = async () => {
    //     const body = {
    //         "userName": "",
    //         "email": this.state.email,
    //         "password": this.state.password,

    //         "phone": "",
    //         "type": this.state.type,
    //         "image": "",
    //         "isActive": true
    //     }
    //     if (this.state.email === "") {
    //         toast.warn("Enter Email")
    //     }
    //     else if (this.state.password === "") {
    //         toast.warn("Enter password")
    //     }
    //     else if (this.state.type === "") {
    //         toast.warn("Enter type")
    //     }
    //     else {
    //         await this.setState({ loading: true })
    //         await login(body)
    //             .then((res) => {
    //                 console.log(res, "suidchsduchs")
    //                 if (res?.success) {
    //                     toast.success(res?.message)
    //                     if (this.state.type === "Customer") {

    //                         this.props.history("/applink")
    //                     }
    //                     else {
    //                         this.props.history("/")
    //                     }
    //                 }
    //                 else {
    //                     toast.warn(res?.message)
    //                     this.setState({ loading: false })
    //                 }

    //             }).catch((err) => {
    //                 toast.warn("Something went wrong")
    //             })
    //     }

    //     // this.props.history("/")
    // }
    updateHandlerOffers = async () => {
        // setLoading(true)
        const promises = this.state.offersUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        this.setState({ offers: links })
        return links
        // setLoading(false)

    }
    updateHandlerGallery = async () => {
        // setLoading(true)
        const promises = this.state.galleryUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        this.setState({ gallery: links })
        return links
        // setLoading(false)

    }
    updateHandlerLogo = async () => {
        // setLoading(true)
        const promises = this.state.logoUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        this.setState({ logo: links[0] })
        return links
        // setLoading(false)

    }
    updateHandlerCover = async () => {
        // setLoading(true)
        const promises = this.state.coverUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        this.setState({ cover: links[0] })
        return links
        // setLoading(false)

    }
    updateHandlerIncorporation = async () => {
        // setLoading(true)
        const promises = this.state.incorporationUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        this.setState({ incorporation: links[0] })
        return links
        // setLoading(false)

    }
    submitHandler = async () => {
        let { name, about, website, offerImage, ntn, galleryImage, mondayo, mondayc, tuesdayc, tuesdayo, wednesdayc, wednesdayo, thursdayc, thursdayo, fridayc, fridayo, saturdayc, saturdayo, sundayc, sundayo, logoImage, coverImage } = this.state

        if (name === "") {
            toast.warn("Please enter name")
        }
        else if (about === "") {
            toast.warn("Please enter about")
        }

        else if (ntn === "") {
            toast.warn("Please enter ntn")
        }
        // else if (!mondayo || !mondayc || !tuesdayo || !tuesdayc || !wednesdayc || !wednesdayo || !thursdayc || !thursdayo || !fridayc || !fridayo || !saturdayc || !saturdayo || !sundayc || !sundayo) {
        //     toast.warn("Please select all timings")
        // }
        else if (logoImage === "") {
            toast.warn("Please select logo")
        }
        else if (this.state.location.latitude === 0) {
            toast.warn("Please select location")
        }
        else if (coverImage === "") {
            toast.warn("Please select cover")
        }

        else {
            let logoLink = await this.updateHandlerLogo()
            const body = {
                "email": this.props.location?.state?.email,
                "userName": this.props.location?.state?.userName,
                "name": this.state.name,
                "image": logoLink[0],
                "password": this.props.location?.state?.password,
                "type": this.props.location?.state.type,
                "isActive": false
            }

            this.setState({ loading: true })

            await signUp(body)
                .then(async (res) => {
                    console.log(res, "suidchsduchs")
                    if (res?.success) {
                        await this.createStore(res?._id, logoLink)
                        // toast.success(res?.message)
                        this.setState({ isOpen: true })
                    }
                    else {
                        toast.warn(res?.message)
                        this.setState({ loading: false })
                    }

                }).catch((err) => {
                    toast.warn("Something went wrong")
                })

        }

    }

    createStore = async (ownerID, logoLink) => {
        let offerLinks = await this.updateHandlerOffers()
        let galleryLinks = await this.updateHandlerGallery()
        let coverLink = await this.updateHandlerCover()
        let incorporationLetterLink = await this.updateHandlerIncorporation()
        let body = {

            name: this.state.name,
            about: this.state.about,
            address: this.state.businessAddress,
            website: this.state.website,
            isActive: false,
            offers: offerLinks,
            gallery: galleryLinks,
            ownerID: ownerID,
            timings: this.state.allTimings,
            logo: logoLink[0],
            cover: coverLink[0],
            ntn: this.state.ntn,
            zipCode: this.state.zipCode,
            country: this.state.country,
            state: this.state.statehermoso,
            city: this.state.city,
            videoLink: this.state.videoLink,
            incorporationLetter: incorporationLetterLink[0],
            phoneNumber: this.state.phoneNumber,
            lat: this.state.location.latitude,
            long: this.state.location.longitude


        }
        await storeAdd(body)
            .then((res) => {
                console.log(res, "suidchsduchs")
                if (res?.success) {
                    toast.success(res?.message)



                }
                else {
                    toast.warn(res?.message)
                    this.setState({ loading: false })
                }

            }).catch((err) => {
                toast.warn("Something went wrong")
            })
    }
    linkhandle = () => {
        this.props.history("/")
    }
    onAddChangeDate = (day, ot, ct) => {
        console.log(day, ot, ct, "alll")
        let obj = {
            day,
            openingTime: ot,
            closingTime: ct
        }

        let allTimings = [...this.state?.allTimings]
        const map = {
            'Monday': 1,
            'Tuesday': 2,
            'Wednesday': 3,
            'Thursday': 4,
            'Friday': 5,
            'Saturday': 6,
            'Sunday': 7
        };
        allTimings.sort((a, b) => {
            return map[a.day] - map[b.day];
        });
        let indx = allTimings.findIndex(f => f.day === day)
        if (indx !== -1) {
            if (!allTimings[indx]?.closingTime)
                allTimings[indx].closingTime = ct
            else if (!allTimings[indx]?.openingTime)
                allTimings[indx].openingTime = ot
            this.setState({ allTimings })
        } else {

            allTimings.push(obj)
            this.setState({ allTimings })
        }


    }

    handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const { lat, lng } = await getLatLng(results[0]);
        this.setState({ locationnew: value, location: { latitude: lat, longitude: lng } });


        // this.setState({ area: results[0].address_components[0] && results[0].address_components[0].long_name })
        // this.setState({ city: results[0].address_components[3] && results[0].address_components[3].long_name })
        // this.setState({ country: results[0].address_components[7] && results[0].address_components[7].long_name })
    };
    render() {
        console.log(this.state.location, 'logo....dekjxdexnkjdne..');
        return (
            <div>
                <Header />
                <AlertModal isOpen={this.state.isOpen} handleClose={() => { this.setState({ isOpen: false }); window.location.href = 'https://service-provider.hermoso.app/' }} />
                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", minHeight: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", minHeight: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>Please fill details of bussiness form</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'> Bussiness Name</p>
                                </div>
                                <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className='register-input' placeholder=' Bussiness Name'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>About</p>
                                </div>
                                <input type="text" value={this.state.about} onChange={(e) => this.setState({ about: e.target.value })} className='register-input' placeholder='About'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Phone</p>
                                </div>
                                <input type="number" value={this.state.phoneNumber} onChange={(e) => this.setState({ phoneNumber: e.target.value })} className='register-input' placeholder='Phone Number'></input>
                                {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Website</p>
                                </div>
                                <input type="text" value={this.state.website} onChange={(e) => this.setState({ website: e.target.value })} className='register-input' placeholder='Website'></input> */}
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Offers</p>
                                </div>
                                <div style={{ width: "100%" }}>
                                    {/* <OffersServiceAddModal
                                        getUploadFiles={(e) => this.setState({ offersUploadFiles: e })}
                                        selectedOffers={this.state.offersUploadFiles}
                                    /> */}
                                    <NewPickerComp getUploadFiles={(e) => this.setState({ offersUploadFiles: e })} selectedImages={e => this.setState({ offerImage: e })} multiple={true} />

                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>NTN</p>
                                </div>
                                <input type="text" value={this.state.ntn} onChange={(e) => this.setState({ ntn: e.target.value })} className='register-input' placeholder='NTN'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Gallery</p>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <NewPickerComp getUploadFiles={(e) => this.setState({ galleryUploadFiles: e })} selectedImages={e => this.setState({ galleryImage: e })} multiple={true} />
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Logo</p>
                                </div>
                                <div style={{ width: "100%" }}>
                                    {/* <LogoServiceAddModal /> */}
                                    <NewPickerComp getUploadFiles={(e) => this.setState({ logoUploadFiles: e })} selectedImages={e => this.setState({ logoImage: e })} multiple={false} />
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Cover</p>
                                </div>
                                <div style={{ width: "100%" }}>
                                    {/* <CoverServiceAddModal /> */}
                                    <NewPickerComp getUploadFiles={(e) => this.setState({ coverUploadFiles: e })} selectedImages={e => this.setState({ coverImage: e })} multiple={false} />
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email'>Zip Code</p>
                                        </div>
                                        <div>
                                            <input style={{ marginTop: "-1.5%" }} type="number" value={this.state.zipCode} onChange={(e) => this.setState({ zipCode: e.target.value })} className='register-input' placeholder='Zip Code'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email'>Country</p>
                                        </div>
                                        <div>
                                            <input style={{ marginTop: "-1.5%" }} type="text" value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })} className='register-input' placeholder='Country'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email'>State</p>
                                        </div>
                                        <div>
                                            <input style={{ marginTop: "-1.5%" }} type="text" value={this.state.statehermoso} onChange={(e) => this.setState({ statehermoso: e.target.value })} className='register-input' placeholder='State'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email'>City</p>
                                        </div>
                                        <div>
                                            <input style={{ marginTop: "-1.5%" }} type="text" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} className='register-input' placeholder='City'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Business Address</p>
                                </div>
                                <input value={this.state.businessAddress} onChange={(e) => this.setState({ businessAddress: e.target.value })} className='register-input' placeholder='Business Address'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Location</p>
                                </div>

                                <PlacesAutocomplete

                                    value={this.state.locationnew}
                                    onChange={(e) => this.setState({ locationnew: e })}
                                    onSelect={this.handleSelect}
                                    required
                                    api
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div style={{ width: "100%" }}>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Search Places ...',
                                                    className: 'location-search-input register-input',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Incorporation Letter</p>
                                </div>
                                <div style={{ width: "100%" }}>
                                    {/* <CoverServiceAddModal /> */}
                                    <NewPickerComp getUploadFiles={(e) => this.setState({ incorporationUploadFiles: e })} selectedImages={e => this.setState({ incorporationLetter: e })} multiple={false} type={"incorporationLetter"} />
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Video link of Youtube</p>
                                </div>
                                <input value={this.state.videoLink} onChange={(e) => this.setState({ videoLink: e.target.value })} className='register-input' placeholder='(Video link of Youtube) Optional'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <p className='email'>Timings (These fields are optional)</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Monday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.mondayo} onChange={(e) => { this.setState({ mondayo: e.target.value }); this.onAddChangeDate("Monday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Closing Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.mondayc} onChange={(e) => { this.setState({ mondayc: e.target.value }); this.onAddChangeDate("Monday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Tuesday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.tuesdayo} onChange={(e) => { this.setState({ tuesdayo: e.target.value }); this.onAddChangeDate("Tuesday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.tuesdayc} onChange={(e) => { this.setState({ tuesdayc: e.target.value }); this.onAddChangeDate("Tuesday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Wednesday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.wednesdayo} onChange={(e) => { this.setState({ wednesdayo: e.target.value }); this.onAddChangeDate("Wednesday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.wednesdayc} onChange={(e) => { this.setState({ wednesdayc: e.target.value }); this.onAddChangeDate("Wednesday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Thursday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.thursdayo} onChange={(e) => { this.setState({ thursdayo: e.target.value }); this.onAddChangeDate("Thursday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.thursdayc} onChange={(e) => { this.setState({ thursdayc: e.target.value }); this.onAddChangeDate("Thursday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Friday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.fridayo} onChange={(e) => { this.setState({ fridayo: e.target.value }); this.onAddChangeDate("Friday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.fridayc} onChange={(e) => { this.setState({ fridayc: e.target.value }); this.onAddChangeDate("Friday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Saturday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.saturdayo} onChange={(e) => { this.setState({ saturdayo: e.target.value }); this.onAddChangeDate("Saturday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.saturdayc} onChange={(e) => { this.setState({ saturdayc: e.target.value }); this.onAddChangeDate("Saturday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Sunday</p>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.sundayo} onChange={(e) => { this.setState({ sundayo: e.target.value }); this.onAddChangeDate("Sunday", e.target.value, null) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                    <div style={{ width: "48%" }}>
                                        <div >
                                            <p className='email2'>Opening Time</p>
                                        </div>
                                        <div>
                                            <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.sundayc} onChange={(e) => { this.setState({ sundayc: e.target.value }); this.onAddChangeDate("Sunday", null, e.target.value) }} className='register-input' placeholder='Opening Time'></input>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => this.submitHandler()} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Submit</p>
                                    }
                                </button>
                                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>or</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div> */}
                                {/* <div style={{ display: "flex" }}>
                                    <div>
                                        <p style={{ color: "black", }}>Already have member? </p>
                                    </div>
                                    <div onClick={() => this.linkhandle()}>
                                        <p style={{ color: "#2A5555", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>Sign in</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div>
        )
    }
}
export default withRouter(formhermoso);