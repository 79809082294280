import React from 'react'
import hermoso from "../assets/hermoso.png"
import { Link } from "react-router-dom"

export default function Header() {
    return (
        <div>
            {/* <div class="nav">
                <input type="checkbox" id="nav-check"></input>
                <div class="nav-header">
                    <div class="nav-title">
                        <img style={{ marginLeft: "15%" }} src={hermoso}></img>
                    </div>
                </div>
                <div class="nav-btn">
                    <label for="nav-check">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                </div>

                <div class="nav-links">
                    <Link to={"/"}><p style={{ margin: "0" }}>Home</p> </Link>
                    <Link to={"/"}>  <p style={{ margin: "0" }}>Products</p></Link>
                    <Link to={"/"}> <p style={{ margin: "0", marginRight: "2%" }}>Login</p></Link>
                    <button className='register-btn'>
                        <Link to={"/register"}> <p className='register-txt'>Register</p></Link>
                    </button>
                </div>
            </div> */}
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", paddingLeft: "2%", paddingRight: "2%" }}>
                <div style={{ width: "180px", height: "75px" }}>
                    <Link to={"/"}> <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img> </Link>
                </div>
                <div>
                    <button className='register-btn'>
                        <Link style={{ textDecoration: "none" }} to={"/register"}> <p className='register-txt'>Register</p></Link>
                    </button>
                </div>
            </div>
        </div>
    )
}
