
import axios from 'axios'
export const Path = 'https://backend.hermoso.app/';
// export const Path = 'https://hermoso-server.herokuapp.com/';
// export const Path = 'http://localhost:8082/'
// export const Path = 'http://192.168.10.8:8082/'
// export const Innovent_Path = 'https://newvirgin.innovent.site/innovent/'
export const PathCustom = 'https://vigin-ba.herokuapp.com/api/v1/';
// export const headers = {
//     "Content-Type": "application/json",
//     "x-api-key": "mKbPeShVmYp3s6v9",
// }
// export const Path = 'http://192.168.18.246:5000/'
let reqInstance = axios.create({
    headers: {
        "x-api-key": "mKbPeShVmYp3s6v9",
        "apikey": "mKbPeShVmYp3s6v9",
    }
})
const signUp = async (payLoad) => {
    const { data } = await axios.post(`${Path}user/signup`, payLoad);
    return data;
}
const checkUser = async (email) => {
    const { data } = await axios.get(`${Path}user/check/${email}`);
    return data;
}
const storeAdd = async (payLoad) => {
    const { data } = await axios.post(`${Path}store/add`, payLoad);
    return data;
}

export {
    signUp,
    storeAdd,
    checkUser
}