import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import popimg from "../assets/popimg.png"
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: 'background.paper',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    alignItems: "center",
    border: "transparent",
};

export default function AlertModal({ isOpen, handleClose }) {
    const [open, setOpen] = React.useState(isOpen || false);
    const handleOpen = () => setOpen(true);


    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <Box sx={style}>
                        {/* <Typography id="transition-modal-title" variant="h6" component="h2">
                            Text in a modal
                        </Typography> */}
                        <div>
                            <img width={100} height={100} src={popimg}></img>
                        </div>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <p style={{ textAlign: "center" }}>  Your application has been successfully submitted our inspection team will visit you with in 3 bussiness days Thankyou</p>
                        </Typography>
                        <button onClick={() => handleClose()} style={{ width: "150px", height: "35px", borderRadius: "10px", backgroundColor: " #2A5555", marginTop: "3%", color: "white", border: "none" }}>Okay</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
