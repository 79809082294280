import React, { Component } from "react";
import Header from "../components/Header";
import back from "../assets/back.png"
import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import DropDown from "../components/DropDown";
import { checkUser, signUp } from "../Path/Path";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import { Icon } from "@iconify/react";
class Register extends Component {
    state = {
        email: "",
        userName: "",
        password: "",
        type: "",
        showPassword: false,
        loading: false,
    }
    handler = async () => {
        const body = {
            "userName": this.state.userName,
            "email": this.state.email,
            "password": this.state.password,
            "type": this.state.type,
        }
        if (this.state.userName === "") {
            toast.warn("Enter userName")
        }
        else if (this.state.email === "") {
            toast.warn("Enter email")
        }
        else if (this.state.password === "") {
            toast.warn("Enter password")
        }
        else if (this.state.type === "") {
            toast.warn("Enter type")
        }
        else {
            this.setState({ loading: true })
            if (this.state.type === "Customer") {
                await signUp(body)
                    .then((res) => {
                        console.log(res, "suidchsduchs")
                        if (res?.success) {
                            toast.success(res?.message)


                            this.props.history("/applink")


                        }
                        else {
                            toast.warn(res?.message)
                            this.setState({ loading: false })
                        }

                    }).catch((err) => {
                        toast.warn("Something went wrong")
                    })
            } else {
                await checkUser(this.state.email)
                    .then((res) => {
                        if (res?.success) {
                            this.props.history("/form", { state: { email: this.state.email, type: this.state.type, password: this.state.password, userName: this.state.userName } })
                        }
                        else {
                            toast.warn(res?.message)
                            this.setState({ loading: false })
                        }

                    }).catch((err) => {
                        toast.warn("Something went wrong")
                    })

            }
        }

    }
    linkhandle = () => {
        this.props.history("/")
    }
    render() {
        console.log(this.state.type, "sguhidch")
        return (
            <div>
                <Header />
                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>Create your account</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>User Name</p>
                                </div>
                                <input value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} className='register-input' placeholder='User Name'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Email</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder='Email Address'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Passwordd</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>

                                    <div style={{ position: "relative", flex: 1 }}>
                                        <input
                                            type={this.state.showPassword ? "text" : "password"}
                                            value={this.state.password}
                                            onChange={(e) => this.setState({ password: e.target.value })}
                                            className='register-input'
                                            placeholder='Password'
                                        />
                                        <span
                                            onClick={() => this.setState(prevState => ({ showPassword: !prevState.showPassword }))}
                                            className="password-toggle-icon"
                                            style={{ position: "absolute", top: "30%", right: "8px", transform: "translateY(-50%)", cursor: "pointer" }}
                                        >
                                            {this.state.showPassword ? <Icon icon="ion:eye-off" /> : <Icon icon="solar:eye-bold" />}
                                        </span>
                                    </div>
                                </div>
                                <div className='drop'>
                                    <label style={{ fontWeight: 500 }}>Type</label>
                                    <DropDown
                                        options={[
                                            { label: 'Select Option', value: 'Select Option' },
                                            { label: 'Service Provider', value: 'Service Provider' },
                                            { label: 'Customer', value: 'Customer' },

                                        ]}

                                        onChange={e => this.setState({ type: e.target.value })}
                                    />
                                </div>
                                <button onClick={() => this.handler()} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Sign Up</p>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}
export default withRouter(Register);