import { storage } from '../firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

const getImageURL = (file, dir, onProgress) => {

    const storageRef = ref(storage, dir || `/files/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);
    return new Promise(resolve => {

        uploadTask.on("state_changed", (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            console.log(progress, "progress ")
            onProgress && onProgress(progress)
        }, (err) => console.log(err, "error"),
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then(res => {

                        return resolve(res)
                    })
            }
        )
    })


}
export {
    getImageURL
}
export default getImageURL